import { type MaybeRef, computed } from 'vue'

import { productListPageAPI, sellerReviewsAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

export function useSellerProductListPage(id: MaybeRef<string>) {
  const sellerProductListPageResponse = useHttpFetch(
    productListPageAPI.getSellerProductListPage,
    {
      pathParams: { id },
    },
  )
  const sellerRatingResponse = useHttpFetch(sellerReviewsAPI.getRating, {
    pathParams: { uuid: id },
  })

  const pending = computed(() => {
    return (
      sellerProductListPageResponse.pending.value &&
      sellerRatingResponse.pending.value
    )
  })

  const rating = computed(() => {
    return sellerRatingResponse.data.value?.averageRate
  })

  const ratesCount = computed(() => {
    return sellerRatingResponse.data.value?.count
  })

  const company = computed(() => {
    return sellerProductListPageResponse.data.value?.company
  })

  const sellerReviewLink = computed(() => {
    return sellerProductListPageResponse.data.value?.links
  })

  const description = computed(() => {
    return sellerProductListPageResponse.data.value?.description
  })
  const vatNumber = computed(() => {
    return sellerProductListPageResponse.data.value?.vat_number
  })

  const siretNumber = computed(() => {
    return sellerProductListPageResponse.data.value?.siret_number
  })

  const country = computed(() => {
    return sellerProductListPageResponse.data.value?.country
  })

  const ageInMonths = computed(() => {
    return sellerProductListPageResponse.data.value?.delta_aged ?? 0
  })

  const address = computed(() => {
    if (sellerProductListPageResponse.data.value?.address) {
      const {
        company: addressCompany,
        street,
        street2,
        country: addressCountry,
        city,
        postal_code: postalCode,
      } = sellerProductListPageResponse.data.value.address

      return [addressCompany, street, street2, postalCode, city, addressCountry]
        .filter(Boolean)
        .join(', ')
    }

    return ''
  })

  const konbiniPhoneNumber = computed(() => {
    return sellerProductListPageResponse.data.value?.custom_data?.japan
      ?.konbini_phone_number
  })

  const legalName = computed(() => {
    return sellerProductListPageResponse.data.value?.merchant_legal_name
  })

  const secondhandDealerNumber = computed(() => {
    return sellerProductListPageResponse.data.value?.custom_data?.japan
      ?.secondhand_dealer_number
  })

  const secondhandDealerNumberIssuingPrefecture = computed(() => {
    return sellerProductListPageResponse.data.value?.custom_data?.japan
      ?.secondhand_dealer_number_issuing_prefecture
  })

  const storeOperationManager = computed(() => {
    return sellerProductListPageResponse.data.value?.custom_data?.japan
      ?.store_operation_manager
  })

  const storeSecurityOfficer = computed(() => {
    return sellerProductListPageResponse.data.value?.custom_data?.japan
      ?.store_security_officer
  })

  const konbiniAddress = computed(() => {
    if (sellerProductListPageResponse.data.value?.address) {
      const {
        company: addressCompany,
        street,
        street2,
        city,
        postal_code: postalCode,
      } = sellerProductListPageResponse.data.value.address

      return [postalCode, city, street, street2, addressCompany]
        .filter(Boolean)
        .join(', ')
    }

    return ''
  })

  const konbiniEmail = computed(() => {
    return sellerProductListPageResponse.data.value?.custom_data?.japan
      ?.konbini_email
  })

  return {
    address,
    ageInMonths,
    company,
    country,
    description,
    konbiniAddress,
    konbiniEmail,
    konbiniPhoneNumber,
    legalName,
    pending,
    ratesCount,
    rating,
    secondhandDealerNumber,
    secondhandDealerNumberIssuingPrefecture,
    sellerReviewLink,
    siretNumber,
    storeOperationManager,
    storeSecurityOfficer,
    vatNumber,
  }
}
