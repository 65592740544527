<template>
  <div>
    <div class="flex flex-col items-center gap-28">
      <h1 class="heading-1">
        {{ company }}
      </h1>
      <div class="flex w-full flex-col items-center gap-4">
        <IconHome class="text-static-success-hi" size="large" />
        <RevLink :to="sellerReviewsPage">
          {{
            i18n(translations.reviewLink, {
              rank: rating,
              maxRating: ratesCount,
            })
          }}
        </RevLink>
      </div>
      <div class="flex flex-col items-center gap-4">
        <IconStarOutlined class="text-static-success-hi" size="large" />
        {{
          i18n(translations.landingSellerAge, {
            year,
            month,
          })
        }}
      </div>
      <div class="w-full">
        <h2 class="heading-2 mb-4">
          {{ i18n(translations.landingSellerDescriptionTitle) }}
        </h2>

        <RevCard class="p-28">
          <p class="body-1">
            {{ description }}
          </p>

          <ul v-if="sellerAttributesOtherCountries.length">
            <li
              v-for="attribute in sellerAttributesOtherCountries"
              :key="attribute.label"
            >
              {{ attribute.label }}
              {{ attribute.value }}
            </li>
          </ul>

          <ul v-if="sellerAttributesKonbini.length">
            <li
              v-for="attribute in sellerAttributesKonbini"
              :key="attribute.label"
              class="flex gap-1"
            >
              {{ attribute.label }}
              {{ attribute.value }}
            </li>
          </ul>

          <ul v-if="sellerAttributesAustralia.length">
            <li
              v-for="attribute in sellerAttributesAustralia"
              :key="attribute.label"
              class="flex gap-1"
            >
              {{ attribute.label }}
              {{ attribute.value }}
            </li>
          </ul>
        </RevCard>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouteParams } from '#imports'
import { computed } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevCard } from '@ds/components/Card'
import { RevLink } from '@ds/components/Link'
import { IconHome } from '@ds/icons/IconHome'
import { IconStarOutlined } from '@ds/icons/IconStarOutlined'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { ROUTES as REVIEWS_ROUTES } from '~/scopes/reviews/route-names'

import { useSellerProductListPage } from '../composables/useSellerProductListPage'

import translations from './AboutSeller.translations'

const i18n = useI18n()
const marketPlace = useMarketplace()

const { uuid: id, merchantName: sellerName } = useRouteParams()
const {
  address,
  ageInMonths,
  company,
  country,
  description,
  konbiniAddress,
  konbiniEmail,
  konbiniPhoneNumber,
  legalName,
  ratesCount,
  rating,
  secondhandDealerNumber,
  secondhandDealerNumberIssuingPrefecture,
  siretNumber,
  storeOperationManager,
  storeSecurityOfficer,
  vatNumber,
} = useSellerProductListPage(id)

const resolveLocalizedRoute = useRouteLocationWithLocale()
const sellerReviewsPage = resolveLocalizedRoute({
  name: REVIEWS_ROUTES.PAGES.MERCHANT,
  params: {
    sellerSlug: sellerName,
    sellerId: id,
  },
})

const year = computed(() => {
  return Math.floor(ageInMonths.value / 12)
})
const month = computed(() => {
  return ageInMonths.value % 12
})

const sellerAttributesOtherCountries = computed(() => {
  if (
    marketPlace.market.countryCode === MarketCountryCode.JP ||
    marketPlace.market.countryCode === MarketCountryCode.AU
  ) {
    return []
  }

  return [
    {
      label: i18n(translations.landingSellerDescriptionVat),
      value: vatNumber.value,
    },
    {
      label: i18n(translations.landingSellerDescriptionSiret),
      value: siretNumber.value,
    },
    {
      label: i18n(translations.landingSellerDescriptionAddress),
      value: address.value,
    },
    {
      label: i18n(translations.landingSellerCountryOrigin),
      value: country.value,
    },
  ]
})

const sellerAttributesKonbini = computed(() => {
  if (marketPlace.market.countryCode !== MarketCountryCode.JP) {
    return []
  }

  const attributes: Array<{ label: string; value?: unknown }> = [
    { label: i18n(translations.konbiniCompanyName), value: legalName.value },
    {
      label: i18n(translations.konbiniStoreName),
      value: company.value,
    },
    {
      label: i18n(translations.landingSellerDescriptionAddress),
      value: konbiniAddress.value,
    },
    {
      label: i18n(translations.konbiniEmail),
      value: konbiniEmail.value,
    },
    {
      label: i18n(translations.konbiniPhoneNumber),
      value: konbiniPhoneNumber.value,
    },
    {
      label: i18n(translations.konbiniSecondHandDealerNumber),
      value: secondhandDealerNumber.value,
    },
    {
      label: i18n(translations.konbiniSecondhandDealerNumberIssuingPrefecture),
      value: secondhandDealerNumberIssuingPrefecture.value,
    },
    {
      label: i18n(translations.konbiniStoreOperationManager),
      value: storeOperationManager.value,
    },
    {
      label: i18n(translations.konbiniStoreSecurityOfficer),
      value: storeSecurityOfficer.value,
    },
  ]

  attributes.push({
    label: i18n(translations.konbiniPhoneExplanationNotice),
  })

  return attributes
})

const sellerAttributesAustralia = computed(() => {
  if (marketPlace.market.countryCode !== MarketCountryCode.AU) {
    return []
  }

  return [
    {
      label: i18n(translations.konbiniCompanyName),
      value: legalName.value,
    },
    {
      label: i18n(translations.landingSellerDescriptionVat),
      value: vatNumber.value,
    },
  ]
})
</script>
